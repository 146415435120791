/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-28 17:50:40
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-05-29 15:18:50
 * @FilePath: /mediatom-web/src/mixins/initDate.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import moment from 'moment'

export default {
  data () {
    return {
      rangePickerDate: ['', '']
    }
  },
  methods: {
    moment,
    initDate (startDays, endDays) {
      const beginDay = new Date().getTime()
      // 今天
      const end = moment(new Date(beginDay - 1000 * 60 * 60 * 24 * endDays))
      // 一星期前
      const start = moment(new Date(beginDay - 1000 * 60 * 60 * 24 * startDays))
      const endDate = end.format('YYYY-MM-DD')
      const startDate = start.format('YYYY-MM-DD')
      // this.rangePickerDate = [start, end]
      return {
        end,
        start,
        endDate, // string
        startDate // string
      }
    },
    // 获取上个月月初和月底到现在的天数的moment对象
    getLastMonthDuration () {
      // 获取当前日期时间
      const currentDate = moment()
      // 获取上个月的第一天和最后一天
      const firstDayOfLastMonth = currentDate.clone().subtract(1, 'months').startOf('month')
      const lastDayOfLastMonth = currentDate.clone().subtract(1, 'months').endOf('month')
      return [firstDayOfLastMonth, lastDayOfLastMonth]
    }
  }
}
